<template>
  <div @keydown.ctrl="handleKeydown" class="app-container">
    <div class="big_box3">
      <el-button size="small" type="primary" @click="mobanClick" plain style="float:right">凭证模板</el-button>
      <!-- <el-checkbox v-model="wbStatus" @change="changeStatus">币种</el-checkbox>
      <el-checkbox v-model="chStatus" @change="changeStatus">存货</el-checkbox> -->
      <!-- <div class="arrow_btn">
        <div class="two_btn arrow_left" @click="lastVou">
          <i class="el-icon-arrow-left"></i>
        </div>
        <div class="two_btn arrow_right" @click="nextVou">
          <i class="el-icon-arrow-right"></i>
        </div>
      </div> -->

      <!-- 顶部标题 -->
      <div class="top_title clearfix">
        <h5>记账凭证</h5>
        <div v-if="templateType == 1" class="tags_title">[税额抵减]</div>
        <div v-if="templateType == 2" class="tags_title">[农产品加计扣除]</div>
        <div v-if="templateType == 3" class="tags_title">[农产品定额扣除]</div>
        <div class="bottom_time clearfix">
          <div class="top_input3 clearfix fl">
            <h6>记</h6>
            <el-input :disabled="id != 0" v-model="content.voucherNo" size="small" style="width:65px"></el-input>
            <span>号</span>
          </div>
          <div class="top_input2 clearfix fl">
            <h6>日期</h6>
            <!--  format="yyyy-MM-dd" -->
            <el-date-picker v-model="content.voucherDate" size="small" style="width:70%;display:inline-block" :disabled-date="disabledDate" type="date" placeholder="选择日期时间"></el-date-picker>
          </div>
        </div>
        <div class="center_title clearfix">
          <!-- <span>
            <el-input disabled style="width:40%;" size="small"></el-input>
          </span>
          <span>年</span>
          <span>
            <el-input disabled style="width:30%;" size="small"></el-input>
          </span>
          <span>期</span> -->
        </div>
        <div class="right_num clearfix">
          <span>附单据</span>
          <span @click="huifu('a',1)">
            <el-input data-index="a_1" v-model="content.billCount" style="width:28%;" size="small"></el-input>
          </span>
          <span>张</span>
        </div>
      </div>
      <!-- 顶部标题end -->

      <!-- content -->
      <div v-auto-focus="focusCtrl" :data-current="currentIndex" :data-action="actionType" class="content2 clearfix">
        <div class="tops">
          <span class="box_top2" style="border-left: none">
            <h6>摘要</h6>
          </span>
          <span class="box_top3">
            <h6>会计科目</h6>
          </span>
          <span class="box_top" v-if="this.wbStatus">
            <h6>币种</h6>
          </span>
          <span class="box_top" v-if="this.chStatus">
            <h6>存货</h6>
          </span>
          <span class="box_top money_box_top">
            <div>借方金额</div>
            <div class="gsbqw">
              <div>百</div>
              <div>十</div>
              <div>亿</div>
              <div>千</div>
              <div>百</div>
              <div>十</div>
              <div>万</div>
              <div>千</div>
              <div>百</div>
              <div>十</div>
              <div>元</div>
              <div>角</div>
              <div>分</div>
            </div>
          </span>
          <span class="box_top money_box_top">
            <div>贷方金额</div>
            <div class="gsbqw">
              <div>百</div>
              <div>十</div>
              <div>亿</div>
              <div>千</div>
              <div>百</div>
              <div>十</div>
              <div>万</div>
              <div>千</div>
              <div>百</div>
              <div>十</div>
              <div>元</div>
              <div>角</div>
              <div>分</div>
            </div>
          </span>
          <div @click="additem()" class="add">
            <i class="iconfont icon-jiahao"></i>
          </div>
        </div>
        <div v-for="(item,indexBig) in items" :key="indexBig" class="bottom clearifx big_box">
          <!-- 摘要 -->
          <div @click="huifu(indexBig,1)" class="common_box2 input-height ccyc">
            <el-input v-if="currentIndex == indexBig + '_1'" :data-index="indexBig + '_1'" v-model="item.summary" @keyup.enter="enter"></el-input>
            <span v-else>{{item.summary}}</span>
          </div>
          <!-- 科目 -->
          <div @click="huifu(indexBig,2)" style="position: relative;" class="common_box3 big_boxcode input-height ccyc">
            <qzf-select v-if="currentIndex == indexBig + '_2'" :data-index="indexBig + '_2'" v-model:signName="item.signName" v-model:subjectId="item.subjectId" v-model:fzhsItemId="item.fzhsId" @success="changeCode($event, item)"></qzf-select>  <!--  @update-enter="enter" -->
            <span v-else class="subjectWidth" style="white-space:break-spaces">{{item.signName}}</span>
            <span style="position: absolute;bottom: 0;left: 0;font-size: 10px;">
              余额：
              <span v-if="item.balance<0" style="color:#F15A24 !important" class="money">{{item.balance}}</span>
              <span v-else class="money">{{item.balance}}</span>
            </span>
          </div>
          <!-- 外币 -->
          <div class="common_box big_boxwb" v-if="wbStatus">
            
            <div v-if="item.wbStatus" class="top_item padding_sty">
              <n-select
                @click="huifu(indexBig,3)"
                :data-index="indexBig + '_3'"
                @update:value="enter"
                style="width:70px"
                size="small"
                v-model:value="item.wb"
                filterable
                placeholder="选择外币"
                :options="wbOptions"
              />
              <input
                :data-index="indexBig + '_4'"
                @click="huifu(indexBig,4)"
                @change="changeWbAmount(item)"
                @keyup.enter="enter"
                v-model="item.wbAmount"
                class="top_input"
                placeholder="外币金额"
              />
            </div>
            <div v-if="item.wbStatus" class="bottom_rate">
              <label>汇率：</label>
              <input
                :data-index="indexBig + '_5'"
                @click="huifu(indexBig,5)"
                @change="changeWbAmount(item)"
                @keyup.enter="enter"
                v-model="item.rate"
                class="top_input"
              />
            </div>


          </div>
          <!-- 存货 -->
          <div class="common_box big_boxcode input-height" v-if="chStatus">
            <!-- v-if="$findCodeIsIn(item.subjectCode,$findCode('codeCh')) || $findCodeIsIn(item.subjectCode,$findCode('codeVoucher5001'))" -->
            <div
              v-if="item.chStatus"
              class="top_item ccyc"
              style="display:block"
            >
              <div class="select_box_big border_none_w">
                <el-select
                @change="handleSelect"
                  size="small"
                  v-model="item.inventoryId"
                  style="margin: 0px;"
                  @visible-change="changeInventory(item)"
                  multiple
                  collapse-tags
                  filterable
                  placeholder="请选择">
                  
                   <el-option label="存货名称" disabled>
                    <div>
                      <!-- <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange"></el-checkbox> -->
                      存货名称
                      <span style="margin-left:60px">金额</span>
                      <span style="margin-left:59px">数量</span>
                      <span style="margin-left:56px">平均单价</span>
                    </div>
                   </el-option>

                  <el-checkbox-group v-model="item.inventoryId">

                    <el-option
                      v-for="item2 in item.inventorys"
                      :key="item2.id"
                      :label="item2.name2"
                      :value="item2.id">
                      <el-checkbox style="pointer-events: none;width:101px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;" :label="item2.id">{{item2.name2}}</el-checkbox>
                      <span>
                        <span style="width:80px;float: right;margin-left:10px">
                          {{item2.price}}
                        </span>
                        <el-input size="small" @click.stop placeholder="数量" type="text" style="width:80px;float: right;margin-top:5px" v-model="item2.count"/>
                        <el-input size="small" @click.stop placeholder="金额" type="text" style="width:80px;float: right;margin-left:15px;margin-right: 5px;margin-top:5px" v-model="item2.amount"/>
                      </span>
                      
                    </el-option>
                  </el-checkbox-group>
                  
                </el-select>
                
              </div>
            </div>

          </div>
          <div @click="huifu(indexBig,9)" class="text_big common_box td-money">
            <div class="big_gsbqw" @click="showInput(item,'in',indexBig)" :style="{letterSpacing:widthAmount + 'px'}" v-show="currentIndex != indexBig + '_9'">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <p v-if="item.inAmount">
                {{(Number(item.inAmount).toFixed(2)*100).toFixed(0)}}
              </p>
            </div>
            <input v-show="currentIndex == indexBig + '_9'" :data-index="indexBig + '_9'" @change="changeInput(item,'in')" @blur="inputBlur(item,'in')" v-model="item.inAmount" type="text" size="small" @keyup.enter="enter"/>
          </div>
          <div @click="huifu(indexBig,10)" class="text_big common_box td-money">
            <div class="big_gsbqw" @click="showInput(item,'out',indexBig)" :style="{letterSpacing:widthAmount + 'px'}" v-show="currentIndex != indexBig + '_10'">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <p v-if="item.outAmount">
               {{(Number(item.outAmount).toFixed(2)*100).toFixed(0)}}
              </p>
            </div>
            <input v-show="currentIndex == indexBig + '_10'" :data-index="indexBig + '_10'" @change="changeInput(item,'out')" @blur="inputBlur(item,'out')" v-model="item.outAmount" type="text" size="small" @keyup.enter="enter"/>
          </div>
          <div class="close" @click="delitem(indexBig,item.id)">
            <i class="iconfont icon-shanchu"></i>
          </div>
          <div style="left: -28px;" @click="additem(indexBig)" class="add">
            <i class="iconfont icon-jiahao"></i>
          </div>
        </div>
        <div class="bottom clearifx">
          <div class="common_box2">
            <p>合计</p>
          </div>
          <div class="common_box3">
            <p class="infarm_box">{{content.bigwrite}}</p>
          </div>
          <div class="common_box" v-if="this.wbStatus">
            <p class="infarm_box"></p>
          </div>
          <div class="common_box" v-if="this.chStatus">
            <p class="infarm_box"></p>
          </div>
          <div class="text_big common_box td-money">
            <div>
              <p :style="{letterSpacing:widthAmount + 'px'}" v-if="content.inSumAmount">
                {{(Number(content.inSumAmount).toFixed(2)*100).toFixed(0)}}
              </p>
            </div>
          </div>

          <div class="text_big common_box td-money">
            <div>
              <p :style="{letterSpacing:widthAmount + 'px'}" v-if="content.outSumAmount">
                {{(Number(content.outSumAmount).toFixed(2)*100).toFixed(0)}}
              </p>
            </div>
          </div>
        </div>
      </div>
      <!-- content-end -->

      <!-- 底部信息 -->
      <div class="bottom_input clearfix">
        <div class="left_box fl">
          <div class="item_btn fl clearfix">
            <h6>制作人：</h6>
            <p style="width:50%;"></p>
          </div>
          <div class="item_btn fl clearfix" style="margin-left:15px;">
            <h6>制作时间：</h6>
            <p style="width:50%;"></p>
          </div>
        </div>
        <div class="right_box fr">
          <div class="item_btn fl clearfix">
            <h6>审核人：</h6>
            <p style="width:50%;"></p>
          </div>
          <div class="item_btn fl clearfix" style="margin-left:15px;">
            <h6>审核时间：</h6>
            <p
              style="width:50%;"
            ></p>
          </div>
        </div>
      </div>
      <!-- 底部信息-end -->



      <div style="width:100%;height:33px">
        <qzf-button jz="false" style="float:left" @success="changeVoucherPre(content)">上一张</qzf-button>
        <qzf-button jz="false" style="float:left" @success="changeVoucherNext(content)">下一张</qzf-button>


        <qzf-button style="float:right;margin-left:10px" type="primary" size="small" @success="handleCreate(content.voucherDate)">保存并新增</qzf-button>
        <qzf-button
          jz="false"
          button_code="pz_xz_bc"
          @success="handleCreate()"
          v-if="content.checkStatus != 1"
          :loading="fullscreenLoading"
          type="primary"
          size="small"
          style="float:right"
        >(ctrl+s)保存</qzf-button>
        <qzf-button jz="false" button_code="pz_xz_bcwmb" type="primary" @success="saveTemplate()" size="small" plain style="float:right;margin-left:10px">保存为模板</qzf-button>
        <qzf-button style="float:right" button_code="pz_xz_jxtj" v-if="content.id" type="primary" @success="keepOn(content.voucherDate)" size="small">(ctrl+q)继续添加</qzf-button>
      </div>

    </div>
    <!-- 凭证模板 -->
    <el-dialog :close-on-click-modal="false" destroy-on-close title="凭证模板" v-model="dialogVisible" width="30%">

      <div class="boss">
        <el-radio :label="1" border size="small"  @change="templateChange" v-model="templateType">税额抵减</el-radio>
        <el-radio :label="2" border size="small"  @change="templateChange" v-model="templateType">农产品加计扣除</el-radio>
        <el-radio :label="3" border size="small"  @change="templateChange" v-model="templateType">农产品定额扣除</el-radio>
      </div>


      <div>
        <p style="text-align: left;">自定义模板：</p>
        <div class="boss"  v-for="(item,index) in voucherTemplateData" :key="index">
          <div @click="templateInfo(item.id)" class="boss-list">
            <p>{{item.name}}</p>
            <i @click.stop="templateDel(item.id)" class="iconfont icon-cuowu"></i> 
          </div>
          
        </div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogVisible = false" size="small">取 消</el-button>
          <el-button type="primary" @click="dialogVisible = false" size="small">确 定</el-button>
        </span>
      </template>
      
    </el-dialog>
    <!-- 确认模板 -->
    <el-dialog :close-on-click-modal="false" destroy-on-close title="提示" v-model="dialogFormVisiblemoban">
      <el-form :model="form">
        <el-form-item label="模板名称" :label-width="formLabelWidth">
          <span @click="huifu('a',5)">
            <el-input style="width:300px" data-index="a_5" v-model="moban.name" size="small"></el-input>
          </span>
        </el-form-item>
        
        <el-form-item label="绑定公司" :label-width="formLabelWidth">
          <el-radio-group disabled v-model="moban.radio">
            <el-radio :label="0">是</el-radio>
            <el-radio :label="1">否</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="dialogFormVisiblemoban = false" size="small">取 消</el-button>  
          <el-button type="primary" @click="handleCreate('muban')" size="small">确 定</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>

import { getVoucherCodeInfo, saveVoucher, voucherInfo, getNewVoucherNo,voucherTemplateList,voucherTemplateSave,voucherTemplateInfo,voucherTemplateDel,voucherNextInfo } from "@/api/voucher.js";

export default {
  name:'addVoucher',
  data() {
    return {
      disabledDate:(time) => {
        let t = this.$store.getters['user/comInfo'].period
        let e = t.slice(0,4) + "-" + t.slice(4,6) + "-01"
        // console.log(new Date(e).getTime(),'new Date(e).getTime()')1648771200000
        // 1648671200000
        return time >= new Date(this.$getLastDay(t)).getTime() || time <= new Date(e).getTime() - 100000000
      },
      fullscreenLoading: false,
      //键盘跳转参数start
      focusCtrl: '0_1',  // 自动聚焦控制,变动时,123执行自动聚焦指令
      currentIndex: '0_1', // 当前聚焦元素的索引
      actionType: 'next', // 自动聚焦的行为类型
      //键盘跳转参数end
      
      wbOptions:[],
      content: {
        inSumAmount: 0,
        outSumAmount: 0,
        bigwrite: "", //大写合计
        voucherDate: new Date(this.nowTime), //时间
        voucherNo: "", //凭证号
        billCount: 1
      },
      widthAmount: 13,
      voucherTemplateData: [],//凭证模板列表
      items: [
        {
          wbStatus: false,
          chStatus: false,
          type: 0,
          summary: "",
          subjectId: 0,
          fzhsId: 0,
          signName: "",
          outAmount: "",
          inAmount: "",
          wbAmount: "",
          wb: "",
          rate: "",
          price: "",
          count: ""
        },
        {
          type: 0,
          summary: "",
          subjectId: 0,
          fzhsId: 0,
          signName: "",
          outAmount: "",
          inAmount: "",
          wbAmount: "",
          wb: "",
          rate: "",
          price: "",
          count: ""
        },
        {
          type: 0,
          summary: "",
          subjectId: 0,
          fzhsId: 0,
          signName: "",
          outAmount: "",
          inAmount: "",
          wbAmount: "",
          wb: "",
          rate: "",
          price: "",
          count: ""
        },
      ],
      wbStatus:false,
      chStatus:false,
      dialogVisible: false,
      dialogFormVisiblemoban: false,
      templateType: 0,
      moban: {
        name: "",
        radio: 0,
      },
      id: 0,
      checkAll: false, //是否全选
      isIndeterminate: false,
      inventorysAll:[],
      biddingStage:[]
    }
  },
  mounted () {
    this.content.voucherDate = new Date(this.nowTime)
  },
  created () {
    let period = this.$store.getters['user/comInfo'].period
    this.getnowTime(period);
    this.init();
  },
  methods:{
    init(){
      let id = this.$store.getters["commons/params"].addVoucherId
      this.id = Number(id)
      if(id){
        this.getInfo(id)
      }else{
        this.keepOn()
      }
    },
    initVoucherNo(){
      getNewVoucherNo({}).then(res=>{
        this.content.voucherNo = res.data.data.info
      })
    },
    handleKeydown(e){
      const { altKey, ctrlKey, shiftKey, key, code } = e;
      if(key == 's'){
        e.preventDefault()
        this.compute()
        this.handleCreate()
      }
      if(key == 'q'){
        e.preventDefault()
        this.keepOn()
      }
    },
    
    keepOn(e) {
      let timeNow = new Date(this.nowTime)
      if(e){
        timeNow = e
      }
      this.templateType = 0;
      this.template1 = false;
      (this.content = {
        inSumAmount: 0,
        outSumAmount: 0,
        bigwrite: "", //大写合计
        voucherDate: timeNow, //时间
        voucherNo: "", //凭证号
        billCount: 1
      }),
        (this.items = [
          {
            wbStatus: false,
            chStatus: false,
            type: 0,
            summary: "",
            subjectId: 0,
            fzhsId: 0,
            signName: "",
            outAmount: "",
            inAmount: "",
            wbAmount: "",
            wb: "",
            rate: "",
            price: "",
            count: ""
          },{
            wbStatus: false,
            chStatus: false,
            type: 0,
            summary: "",
            subjectId: 0,
            fzhsId: 0,
            signName: "",
            outAmount: "",
            inAmount: "",
            wbAmount: "",
            wb: "",
            rate: "",
            price: "",
            count: ""
          },{
            wbStatus: false,
            chStatus: false,
            type: 0,
            summary: "",
            subjectId: 0,
            fzhsId: 0,
            signName: "",
            outAmount: "",
            inAmount: "",
            wbAmount: "",
            wb: "",
            rate: "",
            price: "",
            count: ""
          },
        ]);
        this.initVoucherNo()
    },
    //凭证模板
    mobanClick() {
      voucherTemplateList({}).then(res=>{
        this.voucherTemplateData = res.data.data.list
        this.dialogVisible = true;
      })
    },
    templateChange1() {
      this.items = [
        {
          inventorys: [],
          inventoryIds: [],
          balance: 0,
          inputStatus: 1,
          in_type: false,
          out_type: false,
          type: 1,
          summary: "税额抵减",
          subjectId: "",
          subjectName: "",
          outAmount: "",
          inAmount: "300"
        },
        {
          inventorys: [],
          inventoryIds: [],
          balance: 0,
          inputStatus: 5,
          in_type: false,
          out_type: false,
          type: 1,
          summary: "税额抵减",
          subjectId: "",
          subjectName: "",
          outAmount: "",
          inAmount: "-300"
        }
      ];
      this.compute();
      // this.LazyLoadingCode()
    },
    templateChange3() {
      this.items = [
        {
          inventorys: [],
          inventoryIds: [],
          balance: 0,
          inputStatus: 1,
          in_type: false,
          out_type: false,
          type: 0,
          summary: "领用农产品",
          subjectId: "",
          subjectName: "",
          outAmount: "",
          inAmount: ""
        },
        {
          inventorys: [],
        inventoryIds: [],
          balance: 0,
          inputStatus: 5,
          in_type: false,
          out_type: false,
          type: 0,
          summary: "领用农产品",
          subjectId: "",
          subjectName: "",
          outAmount: "",
          inAmount: ""
        }
      ];
      this.compute();
      // this.LazyLoadingCode()
    },
    // LazyLoadingCode(){
    //   this.items.map(v=>{
    //     let arr = this.subjectList.filter(res=>{
    //       return res.code == v.subjectCode
    //     })
    //     this.$set(v, "subjectList", arr)
    //   })
    // },
    // 凭证模板单选
    templateChange(){
      console.log(this.templateType)
      if(this.templateType == 1){
        this.templateChange1()
      }else if(this.templateType == 2){
        this.templateChange3()
      }
    },
    //保存为模板
    saveTemplate(){
      this.dialogFormVisiblemoban = true
      //凭证模板
      voucherTemplateList({}).then(res=>{
        this.voucherTemplateData = res.data.data.list
      })
    },
    mobanSave(p){
      let param = p
      if(!this.moban.name){
        this.$message.error("请填写模板名称");
        return
      }
      let status = false
      this.voucherTemplateData.forEach(item => {
        if(this.moban.name == item.name){
          status = true
        }
      })
      if(status == true){
        this.$message.error("模板名称重复");
        return
      }
      // let param = {
      //   radio: this.moban.radio,
      //   name: this.moban.name,
      //   inSumAmount: Number(Number(this.content.inSumAmount).toFixed(2)),
      //   outSumAmount: Number(Number(this.content.outSumAmount).toFixed(2)),
      //   voucherDate: this.content.voucherDate, //时间
      //   voucherNo: "记" + this.content.voucher_no,
      //   billCount: this.content.billCount * 1,
      //   voucherItem: this.$qzfCopy(this.items)
      // };
      // param.voucherItem.map(v => {
      //   v.type = v.type * 1;
      //   v.subjectId = v.subjectId * 1;
      //   v.fzhsId = v.fzhsId * 1;
      //   v.outAmount = v.outAmount * 1;
      //   v.inAmount = v.inAmount * 1;
      //   v.inAmountWb = v.type == 1 ? v.wbAmount * 1 : 0;
      //   v.outAmountWb = v.type == 2 ? v.wbAmount * 1 : 0;
      //   v.rate = v.rate * 1;
      //   v.price = v.price * 1;
      //   v.count = v.count * 1;
      //   if(v.inventoryIds){
      //     v.inventoryIds.map((id,i) => {
      //       v.inventoryIds[i] = Number(id)
      //     })
      //   }
      // });
      // if (param.inSumAmount == param.outSumAmount) {
      //   if (this.template1) {
      //     param.type = 1;
      //   } else {
      //     param.type = 0;
      //   }
        this.fullscreenLoading = true;
        param.radio = this.moban.radio
        param.name = this.moban.name
        voucherTemplateSave(param).then(res => {
          this.fullscreenLoading = false;
          if (res.data.msg != "success") {
            this.$message.error(res.data.msg);
          } else {
            this.dialogFormVisiblemoban = false
            this.$notify({
              title: "成功",
              message: "添加凭证模板成功",
              type: "success",
              duration: 2000
            });
          }
        });
      // } else {
      //   this.$notify({
      //     title: "失败",
      //     message: "借贷金额不平,请正确填写",
      //     type: "error",
      //     duration: 2000
      //   });
      // }
    },
    //自定义模板选择
    templateInfo(id) {
      this.templateType = 0
      voucherTemplateInfo({id}).then(res => {
        this.dialogVisible = false
        let data = res.data.data;
        if(data.voucherItem != 0){
          data.voucherItem.map(v=>{
            v.id = 0
          })
          this.items = data.voucherItem
          this.compute();
          this.items.map(v=>{
            if (v.type == 1) {
              v.wbAmount = v.inAmountWb;
            } else {
              v.wbAmount = v.outAmountWb;
            }
            v.inventoryId = []
            if(v.inventoryIds){
              v.inventoryIds.map(z=>{
                v.inventoryId.push(z.inventoryId)
              })
            }
            // v.signName = v.subjectName + v.fzhsName
            this.changeCode(v.subjectId + '-' + v.fzhsId, v, "禁止")
          })
        }
      });
    },
    //自定义模板删除
    templateDel(id) {
      this.$confirm('确认删除此模板?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        voucherTemplateDel({id}).then(res => {
          this.mobanClick()
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
        });
      })
    },
    handleCreate(e){
      
      if(this.$checkSettleStatus() != ""){
        this.$message.error(this.$checkSettleStatus())
        return 
      }
      let s = ""
      for(let i=0;i<this.items.length;i++){
        if(this.items[i].chStatus){
          if(!this.items[i].inventoryId || this.items[i].inventoryId.length == 0){
            this.$message.error("请选择存货")
            return
          }
          let inventoryIds = []
          let sumAmount = 0
          this.items[i].inventorys.map(v=>{
            this.items[i].inventoryId.map(id=>{
              if(id == v.id){
                if(v.count == 0){
                  s = v.name2 + "没有数量"
                }
                // sumAmount = Number(sumAmount + v.amount*1).toFixed(2)
                sumAmount += v.amount*1
                inventoryIds.push({
                  inventoryId: id * 1,
                  amount: v.amount * 1,
                  count: v.count * 1
                })
              }
            })
          })
          this.items[i].inventoryIds = inventoryIds
          if(Number(sumAmount).toFixed(2) != Number(this.items[i].inAmount + this.items[i].outAmount).toFixed(2)){
            this.$message.error("存货金额于借贷金额不符")
            return
          }
        }
      }
      if(s != ""){
        this.$message.error(s)
            return
      }
      let param = {
        id: this.content.id * 1,
        inSumAmount: Number(Number(this.content.inSumAmount).toFixed(2)),
        outSumAmount: Number(Number(this.content.outSumAmount).toFixed(2)),
        voucherDate: this.content.voucherDate, //时间
        voucherNo: this.content.voucherNo?"记" + this.content.voucherNo:"",
        billCount: this.content.billCount * 1,
        voucherItem: this.items,
        fromType: this.content.fromType,
      };
      console.log(this.content.voucherDate);
      if(!param.fromType){
        param.fromType = "手工录入"
      }
      param.voucherItem.map(v => {
        v.type = v.type * 1;
        v.subjectId = v.subjectId;
        v.fzhsId = v.fzhsId;
        v.outAmount = v.outAmount * 1;
        v.inAmount = v.inAmount * 1;
        v.inAmountWb = v.type == 1 ? v.wbAmount * 1 : 0;
        v.outAmountWb = v.type == 2 ? v.wbAmount * 1 : 0;
        v.rate = v.rate * 1;
        v.price = v.price * 1;
        v.count = v.count * 1;
        // v.inventoryIds = []
        // if(v.inventoryIds){
        //   v.inventoryId.map((id,i) => {
        //     v.inventoryIds.push({
        //       inventoryId: id,
        //     })
        //   })
        // }
      });

      //判断是否填写数据 start
      let status = true
      param.voucherItem.map(item => {
        if(item.subjectId != 0 && (item.inAmount != 0 || item.outAmount != 0)) {
          status =false
        }
      })
      if(status){
        this.$notify({
          title: "失败",
          message: "请填写内容",
          type: "error",
          duration: 2000
        });
        return
      }
      //end

      //验证
      if (param.inSumAmount == param.outSumAmount) {
        if (this.templateType == 10) {
          param.type = 0;
        } else {
          param.type = this.templateType
        }
        if(e == 'muban'){
          this.mobanSave(param)
          return
        }
        this.saveVou(param, e)
      } else {
        this.$notify({
          title: "失败",
          message: "借贷金额不平,请正确填写",
          type: "error",
          duration: 2000
        });
      }
    },
    saveVou(param, e){
      this.fullscreenLoading = true;
      saveVoucher(param).then(res => {
        this.fullscreenLoading = false;
        if (res.data.msg == "success") {
          this.$store.dispatch('tagsView/delCachedViewByName', "voucher")
          this.$notify({
            title: "成功",
            message: "添加凭证成功",
            type: "success",
            duration: 2000
          });
          if(e){
            this.keepOn(e)     //保存并新增
            return
          }
          this.getInfo(res.data.data.info.id)
        }
      });
    },
    changeInventory(item){
      // item.inventorys.map(v=>{
      //   v.price = v.price != undefined?v.price*1:0
      //   v.count = v.count != undefined?v.count*1:0
      //   v.amount = v.amount != undefined?v.amount*1:0
      //   v.amount = v.count * v.price
      // })
      let amount = 0 
      item.inventorys.map(v=>{
        item.inventoryId.map(id=>{
          if(v.id == id){
            if(!v.amount){
              v.amount = 0
            }
            amount = Number((amount*1 + v.amount*1)).toFixed(2)
          }
        })
      })
      if(item.type == 2){
        item.outAmount = amount
      } else {
        item.inAmount = amount
      }
      this.compute();
    },
    getInfo(id){
      voucherInfo({ id }).then(res => {
        let voucher = res.data.data.info;
        voucher.voucherNo = voucher.voucherNo.replace("记", "");
        this.content = voucher;
        let items = voucher.voucherItem;
        this.templateType = voucher.type;
        items.map(v => {
          if (v.type == 1) {
            v.wbAmount = v.inAmountWb;
          } else {
            v.wbAmount = v.outAmountWb;
          }
          v.signName = v.subjectName + v.fzhsName
          v.inventoryId = []
          if(v.inventoryIds){
            v.inventoryIds.map(z=>{
              v.inventoryId.push(z.inventoryId)
            })
          }
        });
        this.items = items;
        this.compute();
        this.items.map(v=>{
          this.changeCode(v.subjectId + '-' + v.fzhsId, v, "禁止")
        })
        this.focusCtrl = "0_1" // 自动聚焦控制,变动时,123执行自动聚焦指令
        this.currentIndex = "0_1" // 当前聚焦元素的索引
      });
    },
    //计算外币 存货 单价 汇率
    changeInput(item, type) {
      if (type == "in") {
        //存货外币
        if (item.wbStatus) {
          if (item.wbAmount) {
            item.rate = (item.inAmount / item.wbAmount).toFixed(4);
          }
        }
        if (item.chStatus) {
          // if (item.count) {
          //   item.price = (item.inAmount / item.count).toFixed(2);
          // }
          if(!item.inventoryId || item.inventoryId.length == 0){
            item.inventorys[0].count = 1
            item.inventorys[0].amount = item.inAmount
            item.inventoryId = [item.inventorys[0].id]
          }
        }
      } else {
        //存货外币
        if (item.wbStatus) {
          if (item.wbAmount) {
            item.rate = (item.outAmount / item.wbAmount).toFixed(4);
          }
        }
        if (item.chStatus) {
          // if (item.count) {
          //   item.price = (item.outAmount / item.count).toFixed(2);
          // }
          if(!item.inventoryId || item.inventoryId.length == 0){
            item.inventorys[0].count = 1
            item.inventorys[0].amount = item.outAmount
            item.inventoryId = [item.inventorys[0].id]
          }
        }
      }
    },
    changeCount(item) {
      item.type = 2
      item.outAmount = (item.price * item.count).toFixed(2);
      this.compute();
    },
    changeWbAmount(item) {
      item.type = 1
      item.inAmount = (item.wbAmount * item.rate).toFixed(2);
      this.compute();
    },
    //选择科目后
    async changeCode(sign, item, from){
      if(!sign){
        return
      }
      await getVoucherCodeInfo({id: sign.split('-')[0] * 1, fzhsItemId: sign.split('-')[1] * 1}).then(res=>{
        if(res.data.msg == "success"){
          item.balance = res.data.data.amount
          let inventory = res.data.data.inventory
          if(item.inventoryIds){
            item.inventoryIds.map(v=>{
              inventory.map(z=>{
                if(v.inventoryId == z.id){
                  z.amount = v.amount
                  z.count = v.count
                }
              })
            })
          }
          item.inventorys = inventory
          if(item.inventorys && item.inventorys.length != 0){
            item.chStatus = true
            this.chStatus = true
            if(item.inventorys.length == 0){
              item.inventoryIds = [item.inventorys[0].id]
            }
          }else{ 
            item.chStatus = false
            this.chStatus = false
          }
          item.wb = res.data.data.wb
          if(item.wb && item.wb != "人民币"){
            item.wbStatus = true
            this.wbStatus = true
          }
          if(this.wbOptions.length == 0){
            this.wbOptions = res.data.data.wbs
          }
          this.changeStatus()
        }
      })
      if(from != "禁止"){
        this.enter()
      }
    },
    //回车跳转
    huifu(index,num){
      this.currentIndex = index + '_' + num
      this.focusCtrl = index + '_' + num
      console.log(this.currentIndex)
      this.actionType = 'jump'
    },
    enter(e){
      // e.preventDefault();
      let qian = this.currentIndex.split('_')[0]
      let hou = this.currentIndex.split('_')[1]
      let item = this.items[qian];
      if(hou == 10){
        if(qian == (this.items.length-1)){
          this.additem(qian)
        }
        this.items[qian * 1 + 1].summary = item.summary;
        this.currentIndex = (Number(qian) + 1) + '_1'
        this.focusCtrl = (Number(qian) + 1) + '_1'
        this.actionType = 'jump'
      }else{
        if(item.wbStatus && hou == 2){//外币可填
          this.currentIndex = qian + "_3";
          this.focusCtrl = qian + "_3";
        }else if(hou == 2 || hou == 5){
          // item.chStatus
          if (false) {
            this.currentIndex = qian + "_6";
            this.focusCtrl = qian + "_6";
          } else {
            if (item.outAmount) {
              this.currentIndex = qian + "_10";
              this.focusCtrl = qian + "_10";
            } else {
              item.type = 1;
              this.currentIndex = qian + "_9";
              this.focusCtrl = qian + "_9";
            }
          }
        }else{
          if (hou == 9 && !item.inAmount) {
            item.type = 2;
            this.currentIndex = qian + "_" + (Number(hou) + 1);
            this.focusCtrl = qian + "_" + (Number(hou) + 1);
            this.actionType = "jump";
          } else if (hou == 9 && item.inAmount) {
            if(qian == (this.items.length-1)){
              this.additem(qian)
            }
            this.items[qian * 1 + 1].summary = item.summary;
            this.currentIndex = Number(qian) + 1 + "_1";
            this.focusCtrl = Number(qian) + 1 + "_1";
            this.actionType = "jump";
          } else if(hou == 7){
            item.type = 1;
              this.currentIndex = qian + "_9";
              this.focusCtrl = qian + "_9";
          } else {
            this.currentIndex = qian + "_" + (Number(hou) + 1);
            this.focusCtrl = qian + "_" + (Number(hou) + 1);
            this.actionType = "jump";
          }
        }
      }
      this.changeStatus()
    },
    // 新增 todo
    additem(index) {
      this.items.splice(index + 1, 0, {
        type: 0,
        summary: "",
        subjectId: 0,
        fzhsId: 0,
        signName: "",
        outAmount: "",
        inAmount: "",
        wbAmount: "",
        wb: "",
        rate: "",
        price: "",
        count: ""
      });
    },
    showInput(item, type, index) {
      console.log(type,item);
      if (type == "in") {
        if(item.outAmount){
          item.inAmount = item.outAmount
        }
        item.outAmount = "";
        item.type = 1;
      } else {
        if(item.inAmount){
          item.outAmount = item.inAmount
        }
        item.inAmount = "";
        item.type = 2;
        item.out_type = true;
      }
    },
    compute() {
      console.log("计算")
      //计算
      let in_sum_amount = 0;
      let out_sum_amount = 0;

      this.items.map(v => {
          out_sum_amount += v.outAmount * 1;
          in_sum_amount += v.inAmount * 1;
      });
      in_sum_amount = Number(in_sum_amount).toFixed(2);
      out_sum_amount = Number(out_sum_amount).toFixed(2);
      this.content.inSumAmount = in_sum_amount;
      this.content.outSumAmount = out_sum_amount;
      if (in_sum_amount == out_sum_amount) {
        this.content.bigwrite = this.saveBig(in_sum_amount);
      } else {
        this.content.bigwrite = "";
      }
    },
    delitem(index, id) {
      if (this.items.length <= 1) {
        this.$message.error("仅剩一项禁止删除");
        return;
      }
      this.items.splice(index, 1);
      this.compute();
    },
    inputBlur(item, type) {
      console.log(item,type);
      if (isNaN(item.inAmount && item.outAmount)) {
        this.$message({
          type: "error",
          message: "请输入合法金额数"
        });
        return;
      }
      if (type == "in") {
        console.log("失焦时");
        if (item.inAmount == "=" || item.inAmount == "＝") {
          let amounts = 0;
          this.items.map(v => {
            if (v.inAmount && v.inAmount != "=" && v.inAmount != "＝") {
              amounts = amounts - Number(v.inAmount);
            }
            if (v.outAmount && v.outAmount != "=" && v.outAmount != "＝") {
              amounts = amounts + Number(v.outAmount);
            }
          });
          item.inAmount = amounts.toFixed(2);
        }

        // if (item.type == 1) {
        //   item.balance = (
        //     Number(item.balanceby) + Number(item.inAmount)
        //   ).toFixed(2);
        // } else {
        //   item.balance = (
        //     Number(item.balanceby) - Number(item.inAmount)
        //   ).toFixed(2);
        // }
      } else {
        item.out_type = false;
        if (item.outAmount == "=" || item.outAmount == "＝") {
          let amounts = 0;
          this.items.map(v => {
            console.log(v)
            if (v.inAmount && v.inAmount != "=" && v.inAmount != "＝") {
              console.log(v.inAmount)
              amounts = amounts + Number(v.inAmount);
            }
            if (v.outAmount && v.outAmount != "=" && v.outAmount != "＝") {
              console.log(v.outAmount)
              amounts = amounts - Number(v.outAmount);
            }
          });
          item.outAmount = amounts.toFixed(2);
        }

        // if (item.type == 1) {
        //   item.balance = (
        //     Number(item.balanceby) - Number(item.outAmount)
        //   ).toFixed(2);
        // } else {
        //   item.balance = (
        //     Number(item.balanceby) + Number(item.outAmount)
        //   ).toFixed(2);
        // }
      }
      this.compute();
      // this.enter() //先注释掉跳转 看看有问题嘛
    },
    saveBig(money) {
      let statusFu = false
      if(money < 0){
        money = -money
        statusFu = true
      }
      //汉字的数字
      var cnNums = new Array(
        "零",
        "壹",
        "贰",
        "叁",
        "肆",
        "伍",
        "陆",
        "柒",
        "捌",
        "玖"
      );
      //基本单位
      var cnIntRadice = new Array("", "拾", "佰", "仟");
      //对应整数部分扩展单位
      var cnIntUnits = new Array("", "万", "亿", "兆");
      //对应小数部分单位
      var cnDecUnits = new Array("角", "分", "毫", "厘");
      //整数金额时后面跟的字符
      var cnInteger = "整";
      //整型完以后的单位
      var cnIntLast = "圆";
      //最大处理的数字
      var maxNum = 999999999999999.9999;
      //金额整数部分
      var integerNum;
      //金额小数部分
      var decimalNum;
      //输出的中文金额字符串
      var chineseStr = "";
      //分离金额后用的数组，预定义
      var parts;
      if (money == "") {
        return "";
      }
      money = parseFloat(money);
      if (money >= maxNum) {
        //超出最大处理数字
        return "";
      }
      if (money == 0) {
        chineseStr = cnNums[0] + cnIntLast + cnInteger;
        return chineseStr;
      }
      //转换为字符串
      money = money.toString();
      if (money.indexOf(".") == -1) {
        integerNum = money;
        decimalNum = "";
      } else {
        parts = money.split(".");
        integerNum = parts[0];
        decimalNum = parts[1].substr(0, 4);
      }
      //获取整型部分转换
      if (parseInt(integerNum, 10) > 0) {
        var zeroCount = 0;
        var IntLen = integerNum.length;
        for (var i = 0; i < IntLen; i++) {
          var n = integerNum.substr(i, 1);
          var p = IntLen - i - 1;
          var q = p / 4;
          var m = p % 4;
          if (n == "0") {
            zeroCount++;
          } else {
            if (zeroCount > 0) {
              chineseStr += cnNums[0];
            }
            //归零
            zeroCount = 0;
            chineseStr += cnNums[parseInt(n)] + cnIntRadice[m];
          }
          if (m == 0 && zeroCount < 4) {
            chineseStr += cnIntUnits[q];
          }
        }
        chineseStr += cnIntLast;
      }
      //小数部分
      if (decimalNum != "") {
        var decLen = decimalNum.length;
        for (var i = 0; i < decLen; i++) {
          var n = decimalNum.substr(i, 1);
          if (n != "0") {
            chineseStr += cnNums[Number(n)] + cnDecUnits[i];
          }
        }
      }
      if (chineseStr == "") {
        chineseStr += cnNums[0] + cnIntLast + cnInteger;
      } else if (decimalNum == "") {
        chineseStr += cnInteger;
      }

      if(statusFu){
        chineseStr = "负" + chineseStr
      }

      return chineseStr;
    },
    changeStatus(){
      if (this.wbStatus && this.chStatus) {
        this.widthAmount = 3;
      } else if (this.wbStatus || this.chStatus) {
        this.widthAmount = 6.5;
      } else {
        this.widthAmount = 13;
      }
    },
    getnowTime(period) {
      let year = period.substring(0, 4);
      let month = period.substring(4, 6);
      let day = 0;
      if (month == 4 || month == 6 || month == 9 || month == 11) {
        day = 30;
      } else if (month == 2) {
        day = 28;
      } else {
        day = 31;
      }
      this.nowTime = year + "-" + month + "-" + day;
    },
    changeVoucherPre(content){
      let param = {
        id:content.id,
        type:'pre'
      }
      voucherNextInfo(param).then(res => {
        if(res.data.data.info.id == 0){
          this.$qzfMessage("已经是第一张了", 1)
          return
        }
        this.$store.dispatch('commons/setParam', {addVoucherId: res.data.data.info.id})
        this.$store.dispatch('tagsView/delCachedViewByName', "addVoucher")
        this.$router.push({
          path: "/bookkeeps/addVoucher",
          name: "addVoucher"
        });
        this.init()
      })
    },
    changeVoucherNext(content){
      let param = {
        id:content.id,
        type:'next'
      }
      voucherNextInfo(param).then(res => {
        if(res.data.data.info.id == 0){
          this.$qzfMessage("已经是最后一张了", 1)
          return
        }
        this.$store.dispatch('commons/setParam', {addVoucherId: res.data.data.info.id})
        this.$store.dispatch('tagsView/delCachedViewByName', "addVoucher")
        this.$router.push({
          path: "/bookkeeps/addVoucher",
          name: "addVoucher"
        });
        this.init()
      })
    },
    handleSelect(value) {
      let arrarar = []
      this.items.map(v => {
        if(v.inventorys){
          this.inventorysAll = v.inventorys
          v.inventorys.map(vv => {
            arrarar.push(vv.id)
          })
          return
        }
      })
        const checkedCount = value.length;
        this.checkAll = checkedCount === this.inventorysAll.length;
        this.isIndeterminate = checkedCount > 0 && checkedCount < this.inventorysAll.length;
    },
// // 全选事件
//     handleCheckAllChange(val) {
//       let arrarar = []
//       this.items.map(v => {
//         if(v.inventorys){
//           this.inventorysAll = v.inventorys
//           v.inventorys.map(vv => {
//             arrarar.push(vv.id)
//           })
//           return
//         }

//       })
//       const data = this.inventorysAll.map(item => {
//         return item.id
//       })
//       arrarar == val ? data : [];
//       this.isIndeterminate = false;
//     },
  }
}
</script>

<style lang="scss" scoped>


// 样式  
.el-select-dropdown__item{
    height:37px
  }
.padding_sty {
  border-bottom: 1px solid #eee;
  margin: 5px;
  .top_input {
    width: 50px !important;
    border-bottom: none !important;
  }
}
.top_input {
  width: 100px;
  border-bottom: none !important;
  outline: none;
}
.top_item {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .num_box_item {
    width: 43%;
    flex: 1;
    margin: 5px;
    display: inline-block;
    // display: flex;
    // flex-direction: row;
    // justify-content: flex-start !important;
    // padding: 0 50px 0 10px;
    border-bottom: 1px solid #eee;
    label {
      font-size: 12px;
      color: #333;
      font-weight: normal;
      // flex: 1;
      display: inline-block;
      line-height: 24px;
    }
    input{
      width: 70% !important;
    }
  }


  .top_input {
    flex: 1;
    height: 24px !important;
    display: inline-block;
    background-color: #fff;
    background-image: none;
    // border-radius: 4px;
    // border-bottom: 1px solid #dcdfe6;
    box-sizing: border-box;
    color: #606266;
    font-size: inherit;
    line-height: 24px;
    outline: none;
    padding: 0 6px;
    transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    width: 100%;
  }

  .el-select {
    flex: 1;
    display: inline-block;
  }
}
.big_box3 {
  width: 1250px;
  // height:580px;
  border:1px solid #ddd;
  padding: 20px 60px;
  background: #fff;
  box-shadow: 0 2px 10px #ddd;
  margin: 30px;
  min-height: 500px;
  position: relative;
  .left_sh{
    position: absolute;
    left: 60px;
    top: 20px;
    img{
      width: 100px;
      z-index: 99999999999999;
    }
    .status_true{
      padding: 6px 20px;
      border: 2px solid #67c23a;
      font-size: 14px;
      color: #67c23a;
    }
    .status_false{
      padding: 6px 20px;
      border: 2px solid #f56c6c;
      font-size: 14px;
      color: #f56c6c;
    }
  }
  .moban {
    position: absolute;
    top: 20px;
    right: 50px;
  }
  .arrow_btn {
    // width: 20px;
    // height: 60px;
    text-align: center;
    .two_btn {
      font-size: 20px;
      color: $color-main;
      line-height: 60px;
      background: #eee;
      cursor: pointer;
    }
    .arrow_left {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
    .arrow_right {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .bottom_time {
    float: left;
    width: 40%;
  }
  .top_input2 {
    width: 50%;
    h6 {
      display: inline-block;
      width: 40px;
      font-size: 14px;
      font-weight: normal;
    }
  }
  .top_input3 {
    width: 30%;
    h6 {
      float: left;
      font-size: 15px;
      color: #333;
      font-weight: normal;
      line-height: 26px;
      margin-right: 10px;
    }
    span {
      font-size: 15px;
      color: #333;
      font-weight: normal;
      line-height: 26px;
      margin-left: 10px;
    }
    input {
      float: left;
      width: 47%;
      border: none;
      border-bottom: 1px solid #666;
      margin-left: 5px;
      line-height: 22px;
    }
  }
}
.top_title {
  width: 100%;
  margin: 25px auto;
  position: relative;
  h5 {
    font-size: 24px;
    color: #333;
    text-align: center;
    font-weight: 500;
    margin-bottom: 36px;
  }
  .tags_title{
    font-size: 15px !important;
    color: #666;
    margin-left: 50px;
    position: absolute;
    right: 30%;
    top: 10%;
  }
  
}
.center_title {
  float: left;
  width: 19%;
  margin: 0 auto;
  text-align: center;
  input {
    font-size: 14px;
    color: #333;
    line-height: 36px;
  }
}
.right_num {
  float: right;
  width: 20%;
  text-align: right;
  font-size: 14px;
  color: #333;
}
.content2 {
  width: 100%;
  margin: 0 auto;
  border: 3px solid #999;
  margin-top: 10px;
  position: relative;
}
.tops {
  font-size: 0;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .add {
    width: 1.4em;
    height: 1.4em;
    vertical-align: -0.15em;
    fill: currentColor;
    position: absolute;
    left: -28px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 16px;
    color: $color-main;
    cursor: pointer;
  }
  .gsbqw {
    font-size: 0;
    border-top: 1px solid #f3f3f3;
    div {
      float: left;
      // width: 7.69%;
      width: calc(100% / 13);
      height: 32px;
      font-size: 14px;
      vertical-align: top;
      border-left: 1px solid #f3f3f3;
      // border-top: 1px solid #eee;
    }
     div:nth-child(3) {
    border-color: #FFA9A9;
  }
  div:nth-child(6) {
    border-color: #FFD089;
  }
  div:nth-child(9) {
    border-color: #81ff89;
  }
  div:nth-child(12) {
    border-color: #9dd7ff;
  }
  div:nth-child(1) {
    border-left: none;
  }
  }
  .box_top {
    display: inline-flex;
    flex: 1;
    border-left: 1px solid #666;
    border-bottom: 1px solid #666;
    display: inline-block;
    width:25%;
    height: 68px;
    margin: 0;
    padding: 0;
    text-align: center;
    font-size: 14px;
    vertical-align: top;
    line-height: 68px;
    box-sizing: border-box;
    h6 {
      font-weight: 600;
      font-size: 14px;
      color: #333;
    }
    > div {
      width: 100%;
      height: 34px;
      line-height: 34px;
      font-weight: 500;
    }
    .money_box_top {
      width: 220px;
    }
  }
  .box_top2 {
    display: inline-flex;
    // flex: 1;
    border-left: 1px solid #666;
    border-bottom: 1px solid #666;
    display: inline-block;
    width:20%;
    height: 68px;
    margin: 0;
    padding: 0;
    text-align: center;
    font-size: 14px;
    vertical-align: top;
    line-height: 68px;
    box-sizing: border-box;

    h6 {
      font-weight: 600;
      font-size: 14px;
      color: #333;
    }
    > div {
      width: 100%;
      height: 34px;
      line-height: 34px;
      font-weight: 500;
    }
    .money_box_top {
      width: 220px;
    }
  }
  .box_top3 {
    display: inline-flex;
    // flex: 1;
    border-left: 1px solid #666;
    border-bottom: 1px solid #666;
    display: inline-block;
    width:30%;
    height: 68px;
    margin: 0;
    padding: 0;
    text-align: center;
    font-size: 14px;
    vertical-align: top;
    line-height: 68px;
    box-sizing: border-box;

    h6 {
      font-weight: 600;
      font-size: 14px;
      color: #333;
    }
    > div {
      width: 100%;
      height: 34px;
      line-height: 34px;
      font-weight: 500;
    }
    
    .money_box_top {
      width: 220px;
    }
  }
}
.tops .box_top:first-child {
  border-left: none !important;
}
.bottom {
  width: 100%;
  margin: 0 auto;
  border-bottom: 1px solid #666;
  height: 68px;
  position: relative;
  display: flex;
  p {
    font-size: 14px;
    text-align: center;
    line-height: 36px;
    font-weight: 600;
  }
  .add {
    width: 1.4em;
    height: 1.4em;
    vertical-align: -0.15em;
    fill: currentColor;
    position: absolute;
    right: -28px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 25px;
    color: $color-main;
    cursor: pointer;
    .svg-icon {
      width: 100%;
      height: 100%;
      vertical-align: -0.15em;
      fill: currentColor;
      overflow: hidden;
    }
  }
  .close {
    width: 1.4em;
    height: 1.4em;
    vertical-align: -0.15em;
    fill: currentColor;
    position: absolute;
    right: -32px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 20px;
    color: $color-main;
    cursor: pointer;
  }
}
.bottom:last-child {
  border-bottom: none;
}
.common_box {
  width: 25%;
  flex: 1;
  border-left: 1px solid #666;
  position: relative;
}
.common_box2 {
  width: 20%;
  // flex: 1;
  border-left: 1px solid #666;
  position: relative;
}
.common_box3 {
  width: 30%;
  // flex: 1;
  border-left: 1px solid #666;
  position: relative;
}
.common_box:first-child {
  border-left: 0;
}
.text_big {
  position: relative;
  height: 68px;
  li {
    float: left;
    width: calc(100% / 13);
    height: 68px;
    line-height: 68px;
    text-align: center;
    font-size: 14px;
    color: #333;
    vertical-align: top;
    border-left: 1px solid #f3f3f3;
    z-index: 8;
    position: relative;
    font-weight: bold;
  }
  li:nth-child(1) {
    border-left: none;
  }
  input {
    width: 100%;
    height: 100%;
    background: #fff;
    margin: 0 auto;
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
  }
}
.infarm_box {
  width: 100%;
  height: 68px;
  font-size: 15px;
  line-height: 22px;
  box-sizing: border-box;
  color: #333;
  border: none;
  margin: 0;
  padding: 5px;
  background: none !important;
}
.td-money {
  width: 100%;
  text-align: center;
  position: relative;
  input {
    border: none !important;
    background: none !important;
    width: 100%;
    height: 68px;
    letter-spacing: 2px;
    text-align: right;
    font-size: 22px;
  }
  > div {
    width: 100% !important;
    text-align: right;
    height: 100%;
    // line-height: 67px;
    font-size: 14px;
  }
  span {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 7px;
    top: 0;
  }
}
.bottom_input {
  width: 100%;
  margin: 0 auto;
  margin-top: 30px;
  .item_btn {
    width: 46%;
    margin: 0 auto;
    margin-bottom: 15px;
    position: relative;
    h6 {
      float: left;
      width: 80px;
      font-size: 15px;
      color: #333;
      font-weight: normal;
      line-height: 36px;
      margin-right: 10px;
      text-align: right;
    }
    p {
      float: left;
      line-height: 36px;
      color: #333;
      font-size: 15px;
      font-weight: normal;
    }
    input {
      float: left;
      width: 47%;
      border: none;
      border-bottom: 1px solid #666;
      margin-left: 5px;
      line-height: 22px;
    }
  }
  .left_box {
    width: 46%;
  }
  .right_box {
    width: 46%;
    // text-align: right;
  }
}
.big_boxcode .el-input__inner {
  width: 100%;
  // height: 67px !important;
  // line-height: 67px !important;
  border-radius: 0px !important;
  border: 1px solid #dcdfe6;
}
.big_boxwb .el-input__inner {
  height: 22px !important;
  line-height: 20px !important;
  border-radius: 0px !important;
  border: none !important;
  padding: 0 10px !important;
  font-size: 14px;
}
.big_gsbqw {
  font-size: 0;
  border-top: 1px solid #eee;
  position: relative;
  height: 68px;
  p{
    position: absolute;
    left: 2px;
    top: 0;
    height: 68px;
    line-height: 68px !important;
    font-size: 14px;
    font-weight: normal !important;
    text-align: right !important;
    width: 100%;
  }
  div {
    float: left;
    width: calc(100% / 13);
    height: 65px;
    font-size: 14px;
    vertical-align: top;
    border-left: 1px solid #eee;
  }
  div:nth-child(3) {
    border-color: #FFA9A9;
  }
  div:nth-child(6) {
    border-color: #FFD089;
  }
  div:nth-child(9) {
    border-color: #81ff89;
  }
  div:nth-child(12) {
    border-color: #9dd7ff;
  }
  div:nth-child(1) {
    border-left: none;
  }
}
.ccyc{
  overflow: hidden;
  text-overflow:ellipsis;
  white-space: nowrap;
}
.boss {
  display: inline-block;
  margin-bottom: 20px;
  position: relative;
  .iconfont icon-cuowu{
    position: absolute;
    right: 2px;
    top: 2px;
    color: #f56c6c;
  }
  .boss-list{
    padding: 5px 10px;
    font-size: 14px;
    border: 1px solid #dcdfe6;
    border-radius: 2px;
    margin-right: 10px;
    margin-top: 10px;
    cursor: pointer;
  }
}
.subjectWidth{
  word-break: break-all;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
</style>

<style>
.input-height .el-input__inner{
    height: 67px;
}
.input-height .n-base-selection-label, .n-base-selection-input{
  height: 67px;
}
</style>